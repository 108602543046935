<template>
    <CRow>
        <vue-confirm-dialog></vue-confirm-dialog>

        <CCol>
            <CCol col="12" lg="6">
                <CCard>
                    <CCardBody>
                        <CInput
                            id="Name"
                            label="名前"
                            placeholder="名前"
                            v-model="item.name"
                            :invalid-feedback="errors && errors.name ? errors.name[0] : ''"
                            :is-valid="errors.name ? false : undefined"
                        >
                        </CInput>
                        <CInput v-if="isColorPage"
                            id="Code"
                            label="コード"
                            placeholder="コード"
                            v-model="item.color_code"
                            :invalid-feedback="errors && errors.color_code ? errors.color_code[0] : ''"
                            :is-valid="errors.color_code ? false : undefined"
                        >
                        </CInput>
                    </CCardBody>
                    <CCardFooter>
                        <CButton class="mr-3" color="primary" @click="goBack">戻る</CButton>
                        <CButton color="primary" @click="handleConfirm" >{{$route.params.id ? '更新' : '追加する'}}</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ResourceApi from "@/api/resourceApi";
    import Vue from "vue";

    export default {
        name: 'CreateBasicComponent',
        props: {
            endPoint: String,
            title: String,
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.itemOpened = from.fullPath.includes(this.endPoint)
            })
        },
        data () {
            return {
                errors: {},
                updateApi: Function,
                item: {
                    id: this.$route.params.id,
                    name: '',
                },
                isUpdate: false,
                isColorPage: this.endPoint === 'colors'
            }
        },
        created() {
            this.itemData();
        },
        methods: {
            goBack() {
                this.itemOpened ? this.$router.go(-1) : this.$router.push({path: '/'+ this.endPoint})
            },
            handleConfirm(){
              const id = this.$route.params.id
                this.$confirm(
                    {
                        title: '確認',
                        message: id ? `更新しますか？` : '追加しますか？',
                        button: {
                            no: 'いいえ',
                            yes: 'はい'
                        },
                        callback: confirm => {
                            if (confirm) {
                                // ... do something
                                this.handleUpdateOrCreateItem()
                            }
                        }
                    }
                )
            },
            handleUpdateOrCreateItem() {
                const api = new ResourceApi(this.endPoint)
                this.loading = true
                let param = this.item;
                this.confirmModal = true;
                const id = this.$route.params.id
                this.updateApi = api.create;
                if(id){
                    this.updateApi = api.edit;
                }
                this.updateApi(param).then(response => {
                  if(id){
                    Vue.$toast.success('更新は成功しました。')
                  }else {
                    Vue.$toast.success('登録に成功しました。')
                  }
                    setTimeout(this.goBack(), 2000);
                }).catch(error => {
                    this.errors =  error.response?.data?.errors;
                }).finally(() =>{
                    this.loading = false
                })
            },
            itemData () {
                const api = new ResourceApi(this.endPoint)
                const id = this.$route.params.id
                if(!id){
                    return
                }
                this.isUpdate = true
                api.show({id: id}).then(response => {
                    // console.log(response.data)
                    this.item = response.data;
                    this.item.id = this.$route.params.id
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },
        }
    }
</script>
